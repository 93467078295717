import styled from "styled-components";

export const ServicesWrapper = styled.div`
  background: #e6e6e6;
  font-family: "brother-1816", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  padding-top: 50px;

  h1 {
    font-size: 3em;
    padding-bottom: 0.5em;
  }
`;

export const ServicesContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 80%; /* Increase width for better spacing */
  margin: 0 auto;
  text-align: left;
  text-decoration: none;
  gap: 20px; /* Add gap for spacing between columns */

  div {
    margin: 0 auto;
    padding: 10px; /* Add padding for better spacing inside divs */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Ensure items start at the same spot */
  }

  h4 {
    margin: 0 0 10px 0; /* Add margin below headers */
  }

  p {
    margin: 0; /* Remove margin for paragraphs */
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    div {
      padding: 0; /* Remove padding for mobile view */
      align-items: center; /* Center items for mobile view */
    }

    li {
      padding: 5px 0;
    }
  }
`;
