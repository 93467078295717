import React from "react";
import { AboutWrapper } from "./AboutStyles";

const About = () => (
  <AboutWrapper>
    <h1 id="about">Everbold Landscaping</h1>
    <p>
      Welcome to Everbold Landscaping, where creativity meets craftsmanship to
      transform your outdoor space into a work of art.
      <br />
      <br />
      With a passion for innovation and a commitment to excellence, we
      specialize in creating bespoke landscapes that not only enhance the beauty
      of your property but also elevate your lifestyle. From stunning design
      concepts to meticulous installation and ongoing maintenance, we're
      dedicated to exceeding your expectations every step of the way.
      <br />
      <br />
      Explore the possibilities with Everbold Landscaping and let us bring your
      outdoor dreams to life.
      <br />
      <br />
      <h2 style={{ textAlign: "center" }}>Meet the Owner</h2>
      Welcome! I'm Alex Weinheimer, the proud Owner and Landscaper behind
      Everbold Landscaping. With over a decade of experience in the industry, my
      journey has been fueled by a deep-rooted passion for innovative design and
      a love for the natural world.
      <br />
      <br />
      Through Everbold, I've cultivated this passion into one of the region's
      premier landscaping companies. Let's collaborate to transform your outdoor
      space into a masterpiece reflective of my artistry and your vision
      {/* Alex has been around the landscape trade his entire life, and is a firm
      believer in going above-and-beyond to provide complete customer
      satisfaction.
      <br />
      <br />
      Regardless of the size and scope of your project, communication is always
      the top priority. We strive for complete satisfaction during the entire
      process; from initial contact to final approval of all work performed.
      <br />
      <br />
      Many businesses fail to provide these core qualities to the customer, and
      their landscaping vision can get lost, resulting in poor project execution
      and an unhappy customer.
      <br />
      <br />
      Your satisfaction is the most important aspect of what we do here at
      Weekend Landscaping.
      <br />
      <br />
      Whether your project is large or small, Alex is personally focused on all
      services Weekend Landscaping provides.
      <br />
      <br />
      We know you have many choices when selecting a company to provide
      landscaping services. We know you want to be sure before you invest your
      hard-earned money. We encourage you to contact our current and past
      customers for their honest feedback on our work with them.
      <br />
      <br />
      We want to be the first call you make when looking for landscaping
      services.
      <br />
      <br />
      Many of our clients are repeat customers. We hope you will be too!
      <br />
      <br />
      <strong>
        We hope to earn your business with the way we do business!
      </strong>
      <br />
      <br />
      <strong>
        We service Clermont, Butler, and Hamilton Counties in Ohio.{" "}
      </strong> */}
    </p>
  </AboutWrapper>
);

export default About;
