import styled from "styled-components";

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff9f0;
  z-index: 2;
  position: relative;
  align-items: center;
  width: 100%;
  font-family: "brother-1816", sans-serif;
  font-weight: 400;
  font-style: normal;

  @media screen and (max-height: 500px) {
  }

  h1 {
    font-size: 4em;
    margin-bottom: 25px;
    padding-top: 1em;
  }

  .intro {
    text-align: center;
    margin: 0 auto;
    width: 25%;

    @media screen and (max-width: 1150px) {
      width: 85%;
    }

    @media screen and (max-width: 450px) {
      background: #433735;
      width: 100%;
    }

    @media screen and (max-width: 400px) {
      width: 100%;
      font-size: 0.75em;
      padding-top: 100px;

      h2 {
        margin: 0 auto;
        width: 85%;
      }
    }
  }

  button {
    margin-top: 50px;
    background: transparent;
    border: 2px solid white;
    color: #fff9f0;
    width: 10rem;
    height: 5rem;
    font-size: 1.5rem;
    font-weight: 700;
    transition: color 0.3s linear, border-color 0.3s linear;

    &:hover {
      color: #adcf4e;
      border-color: #adcf4e;
    }

    @media screen and (max-width: 450px) {
      margin-bottom: 50px;
    }
  }
`;
