import React from "react";
import { ContactWrap } from "./ContactMeStyles";
import emailjs from "emailjs-com";
import Swal from "sweetalert2/dist/sweetalert2.all.js";

const ContactMe = () => {
  function sendEmail(e) {
    e.preventDefault();

    const name = e.target.name.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const address = e.target.address.value;
    const subject = e.target.subject.value;
    const message = e.target.message.value;
    console.log(name);
    console.log(email);
    console.log(phone);

    console.log(address);
    console.log(subject);
    console.log(message);

    emailjs
      .send(
        "service_g5o1icl",
        "template_ijhorez",
        {
          name: name,
          email: email,
          phone: phone,
          address: address,
          message: message,
          subject: subject,
        },
        "user_AtqFI3nvTWlplzNyaEPlg"
      )
      .then(
        (result) => {
          Swal.fire({
            title: "Form submitted successfully!",
            icon: "success",
            showConfirmButton: true,
            timer: 2000,
          });
        },
        (error) => {
          console.log(error);
        }
      );

    // emailjs
    //   .sendForm(
    //     "service_g5o1icl",
    //     "template_ijhorez",
    //     e.target,
    //     "user_AtqFI3nvTWlplzNyaEPlg"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       Swal.fire({
    //         title: "Form submitted successfully!",
    //         icon: "success",
    //         showConfirmButton: true,
    //         timer: 2000,
    //       });
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );

    e.target.reset();
  }

  return (
    <ContactWrap>
      <div>
        <h1 id="contactme">Let's Talk</h1>
        <h3>Fill out the below form and we will be in touch.</h3>
        <form className="formContainer" onSubmit={sendEmail}>
          <input type="text" placeholder="Name" name="name" required />
          <input type="text" placeholder="Email Address" name="email" />
          <input type="text" placeholder="Phone #" name="phone" />
          <input type="text" placeholder="Address" name="address" />
          <input type="text" placeholder="Subject" name="subject" required />
          <textarea
            type="text"
            placeholder="Type Your Message"
            name="message"
            required
          />
          <input className="submit" type="submit" />
        </form>
        <div style={{ marginTop: "15px" }}>
          <h4>Hours</h4>
          <p>Monday-Friday 7am-5pm</p>
          <p>Saturday-Sunday 8am-3pm</p>
        </div>
      </div>
    </ContactWrap>
  );
};

export default ContactMe;
