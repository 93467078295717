import React from "react";
import { FooterStyles } from "./FooterStyles";

const Footer = () => (
  <FooterStyles>
    <div className="divider"></div>
    <div>Everbold Landscaping Copyright © 2024</div>
  </FooterStyles>
);

export default Footer;
